import React from 'react';
import '../../CSS/technicalPage.css'


const Technical = () => {
  return (
    <>
    <div className="techPage">
    <div className="technicalHeading">TRINITY TECHNICAL</div>
    <div className="technicalMsg"></div>
    </div>
      
    </>
  )
}

export default Technical