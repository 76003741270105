import React from 'react';
import '../../CSS/culturalPage.css'

const Cultural = () => {
  return (
    <>
     <div className="culturalPage">
    <div className="culturalHeading">TRINITY CULTURAL</div>
    <div className="culturalMsg"></div>
     </div>
    </>
  )
}

export default Cultural